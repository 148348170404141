import "./App.css";

function App() {
  return (
    <div className="App">
      <h1>WTFGTFO</h1>
      <h2>A DEFI LAUNCHPAD</h2>
      <h2>
        <a
          rel="noreferrer"
          href="https://github.com/ghoul-keeper/ghoulies"
          target="_blank"
        >
          Solana Provenance
        </a>
        <img alt="Logo" class="lg" src="/ghoulie-gang-logo.png" />
      </h2>
    </div>
  );
}

export default App;
